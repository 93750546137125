.Footer {
  margin-top: 25px;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0.6;

  .FooterLogo {}

  .FooterYear {
    margin-top: 6px;
    font-size: 12px;
    line-height: 1;
  }
}