html, body {
  background: #121212;
  color: #eee;
  font-family: Roboto, sans-serif;
  line-height: 1.5;
}

.ReactModalPortal {
  .ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.8) !important;
    z-index: 200;
  }

  .ReactModal__Close {
    padding: 15px;
    position: absolute;
    top: 0;
    font-size: 32px;
    line-height: 1;
    right: 0;
    z-index: 1;
    box-sizing: border-box;
    cursor: pointer;
  }

  .ReactModal__Content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 0 !important;
    border-radius: 0 !important;
    border: 0 !important;
    background: transparent !important;
    inset: 0 !important;
    overflow: hidden !important;

    img {
      padding: 15px;
      width: 100%;
      height: 100%;
      object-fit: contain;
      box-sizing: border-box;
    }
  }
}