.Tile {
  display: inline-block;
  width: 100%;

  .Wrapper {
    margin: 5px;
    animation: loading 800ms linear infinite alternate;
    border-radius: 8px;
    overflow: hidden;

    .Image {
      width: 100%;
      display: block;
      cursor: pointer;
    }
  }
}

@keyframes loading {
  0% {
    background-color: rgb(255 255 255 / 10%);
  }

  100% {
    background-color: rgb(255 255 255 / 15%);
  }
}
