.Spinner {
  padding: 15px;
  margin: 0 auto;
  display: inline-block;
  width: 56px;
  height: 56px;

  &:after {
    content: " ";
    display: block;
    width: 40px;
    height: 40px;
    margin: 6px;
    border-radius: 50%;
    border: 3px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: spinner-animation 1.2s linear infinite;
  }
}

@keyframes spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}