.Container {
  padding: 91px 0 0 0;
  display: flex;
  flex-direction: column;

  .Tile {
    width: 20%;

    @media (max-width: 2048px) {
      width: 25%;
    }

    @media (max-width: 1024px) {
      width: 33.333%;
    }

    @media (max-width: 768px) {
      width: 50%;
    }

    @media (max-width: 420px) {
      width: 100%;
    }
  }
}