.Header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  padding: 25px 25px 15px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background-color: rgba(18, 18, 18, 0.8);
  backdrop-filter: blur(8px);
  cursor: pointer;
  transition: all linear 100ms;

  &.Short {
    padding: 15px 25px;
  }
}