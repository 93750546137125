.MainPage {
  padding: 0 15px;
  margin: 0 auto;
  width: 100%;
  max-width: 80vw;
  box-sizing: border-box;

  @media screen and (max-width: 1280px) {
    max-width: none;
  }
}